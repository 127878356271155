import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import { fuseConfig } from '../../fuse-config';

@Injectable({
    providedIn: 'root'
})
export class MonitorService {
    private subject;
    constructor(private http: HttpClient) {
      this.subject = new Subject();
    }

    subscribe(next, error?, complete?): any {
        return this.subject.subscribe(next, error, complete);
    }
    
    getLiveMonitorEvents(building, gate): Observable<any> {
        let search = '';
        if (building || gate) {
            search = '?filter=';
            if (building) {
                search += `building:eq:${building}|`;
            }
            // if (gate) {
            //     search += `gate:eq:${gate}|`;
            // }
            search = search.slice(0, -1);
        }
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + '/shared/monitor/doorEvents'+search, {headers: headers, withCredentials: true});
    }
    
    getPhotoEvent(gtc_port, eventId): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        return this.http.get( fuseConfig.url + '/shared/monitor/photoEvent/' + gtc_port + '/' + eventId, {headers: headers, withCredentials: true});
    }
    
}